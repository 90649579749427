<template>
   <WorkforceManagement />
</template>

<script>
import WorkforceManagement from '@/components/workforce/WorkforceManagement.vue';

export default {
  name: 'WorkforceView',
  components: {
    WorkforceManagement
  }
}
</script>
